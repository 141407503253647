 
import Navbar from '@/components/Navbar/index.vue'

/**
 * 注册全局自定义组件
 * @param {Vue.App} app - Vue 应用实例
 */
const registerGlobalComponents = (app) => { 
  app.component('Navbar', Navbar)
}

export default registerGlobalComponents