import TencentCloudChat from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
import { useUserStore } from '@/stores/modules/user'


let options = {
  SDKAppID: import.meta.env.VITE_SDK_APPID // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
const chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示

// chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
chat.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
 
// 登出
const logout = async () => {
  try {
    chat.logout();
  } catch (error) {
    console.log(error);
  }
}

// 会话未读总数变更通知
let onTotalUnreadMessageCountUpdated = function(event) { 
  const userStore = useUserStore()
  userStore.totalUnreadCount = event.data
};
chat.on(TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, onTotalUnreadMessageCountUpdated);
 
 

export {
  chat, 
  logout
};