export default {
  mounted(el, binding) {
    const { value: callback, arg: ms = 500 } = binding;

    let timer = null;
    let isLongPress = false;

    function start(event) {
      event.preventDefault();
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        isLongPress = true;
        callback(event);
      }, ms);
    }

    function end() {
      if (timer) clearTimeout(timer);
      if (!isLongPress) {
        // 处理普通点击逻辑（如果需要）
      } else {
        isLongPress = false;
      }
    }

    const events = ['pointerdown', 'pointerup', 'pointercancel', 'pointerleave'];
    const handlers = {
      pointerdown: start,
      pointerup: end,
      pointercancel: end,
      pointerleave: end,
    };

    // 添加事件监听器
    events.forEach(event => {
      el.addEventListener(event, handlers[event]);
    });

    // 当指令与元素解除绑定时触发
    el._vue_long_press_cleanup = () => {
      events.forEach(event => {
        el.removeEventListener(event, handlers[event]);
      });
    };
  },
  unmounted(el) {
    // 移除事件监听器
    if (el._vue_long_press_cleanup) {
      el._vue_long_press_cleanup();
      delete el._vue_long_press_cleanup;
    }
  }
};