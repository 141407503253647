<template>
  <van-dialog
    v-model:show="show_"
    :close-on-click-overlay="_options.closeOnClickOverlay"
    @close="close"
    :overlayClass="[type > 1 ? 'frosted-glass' : '']"
    class="min-h-[24.55%] max-w-[80.19%] py-[24px]"
    theme="round-button"
  >
    <template #title>
      <div class="mt-[12px] flex justify-center">
        <svg-icon v-if="type === 0" name="thumbs-up1" className="w-[64px] h-[64px]"></svg-icon>
        <svg-icon v-if="type === 1" name="11438" className="w-[64px] h-[64px]"></svg-icon>
        <svg-icon v-if="type === 2" name="logout" className="w-[64px] h-[64px]"></svg-icon>
        <svg-icon v-if="type === 3" name="closeeye" className="w-[64px] h-[64px]"></svg-icon>
        <svg-icon v-if="type === 4" name="logout" className="w-[64px] h-[64px]"></svg-icon>
        <svg-icon v-if="type === 5" name="11439" className="w-[64px] h-[64px]"></svg-icon>
      </div>
    </template>
    <template #default>
      <div class="my-[24px] w-full justify-center text-center text-base text-cornflower">
        <span v-if="type === 0">送过礼物之后才能赞/踩ta哦</span>
        <span v-if="type === 1">解锁微信号</span>
        <span v-if="type === 2">该用户已注销账号</span>
        <span v-if="type === 3">对方设置了同性用户不可查看资料， <br />在 <span class="text-accent-content">设置-交友设置 </span>中可更改设置。</span>
        <span v-if="type === 4">该用户账号已被冻结</span>
        <span v-if="type === 5">对方设置了同性用户不可发起私聊， <br />在 <span class="text-accent-content">设置-交友设置 </span>中可更改设置。</span>
      </div>
    </template>
    <template #footer>
      <!--  送礼    -->
      <div v-if="type === 0" class="flex w-full items-center justify-center">
        <button class="btn mx-auto h-[40px] w-[288px] rounded-[4px] bg-accent-content font-bold text-chestnut" @click="_callback">送礼物</button>
      </div>
      <!--  解锁微信    -->
      <div v-if="type === 1" class="flex w-full flex-col items-center justify-center">
        <!--        <button v-if="type === 0" class="btn mx-auto h-[40px] w-[288px] bg-accent-content text-chestnut" @click="_callback">送礼物</button>-->
        <button
          class="btn mx-auto mb-[24px] h-[40px] w-[288px] rounded-[4px] font-bold text-chestnut"
          :disabled="lastCount === 0"
          :class="[lastCount === 0 ? 'bg-[#666666] text-white' : 'bg-accent-content text-chestnut']"
          @click="() => _callback($event, _options.unLockWechatCallback)"
        >
          {{ `使用会员特权查看【剩余${lastCount}次】` }}
        </button>
        <button
          class="btn mx-auto mb-[24px] h-[40px] w-[288px] rounded-[4px] bg-accent-content text-chestnut"
          @click="() => _callback($event, _options.unlockWeChatByPointsCallback)"
        >
          {{ `消耗${options.wxUnlockIntegralNum}颜值币查看微信号` }}
        </button>
      </div>
      <!--  用户已注销    -->
      <div v-if="type === 2" class="flex w-full items-center justify-center">
        <button class="btn mx-auto h-[40px] w-[288px] rounded-[4px] bg-accent-content font-bold text-chestnut" @click="_callback">我知道了</button>
      </div>
      <!--  同性不可查看    -->
      <div v-if="type === 3" class="flex w-full items-center justify-center">
        <button class="btn mx-auto h-[40px] w-[288px] rounded-[4px] bg-accent-content font-bold text-chestnut" @click="_callback">确定</button>
      </div>
      <!--  用户已被冻结    -->
      <div v-if="type === 4" class="flex w-full items-center justify-center">
        <button class="btn mx-auto h-[40px] w-[288px] rounded-[4px] bg-accent-content font-bold text-chestnut" @click="_callback">确定</button>
      </div>
      <!--  同性禁止私聊    -->
      <div v-if="type === 5" class="flex w-full items-center justify-center">
        <button class="btn mx-auto h-[40px] w-[288px] rounded-[4px] bg-accent-content font-bold text-chestnut" @click="_callback">确定</button>
      </div>
    </template>
  </van-dialog>
</template>
<script>
const defaultOptions = {
  wxUnlockIntegralNum: 0, //解锁所需积分点数
  unLockWechatCallback: () => {}, //微信解锁回调
  unlockWeChatByPointsCallback: () => {}, //消耗货币解锁微信回调
  closeOnClickOverlay: true,
}
</script>
<script setup>
import { ref, onMounted, defineProps, nextTick, computed, watch } from 'vue'
import { getRemainingWeChatUnlockAttemptsForMember } from '@/api/settings/index.js'

const lastCount = ref(10) //会员解锁微信剩余次数
const show_ = ref(false)
const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  close: {
    type: Function,
  },
  type: {
    type: Number,
    default: 0,
    /*
     * 0 先送礼物才能进行点赞
     *  1 解锁微信号
     * 2 该用户已注销账号
     * 3 同性用户不可查看
     * 4 用户已被冻结
     * 5 同性禁止私聊
     *
     * */
  },
  options: {
    type: Object,
    default: () => defaultOptions,
  },
  callback: {
    type: Function,
    default: () => {},
  },
})
const _callback = (e, cb) => {
  show_.value = false
  props.callback()
  cb && cb()
}
const _options = { ...defaultOptions, ...props.options }
watch(
  () => props.show,
  (newVal) => {
    show_.value = newVal
  },
  { immediate: true, deep: true },
)
const close = () => {
  console.log('close')
}
onMounted(() => {
  if (props.type === 1) {
    getRemainingWeChatUnlockAttemptsForMember().then((res) => {
      lastCount.value = res.data
    })
  }
})
</script>
<style>
.frosted-glass {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  -webkit-backdrop-filter: blur(0.25rem);
  backdrop-filter: blur(0.25rem);
}
</style>
<style scoped>
.van-overlay {
  @apply bg-[#0000004d];
}
</style>
