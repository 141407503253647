<script setup>
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  isFixed: {
    type: Boolean,
    default: true,
  },
  activeColor: {
    type: String,
    default: '#FFDFBE',
  },
  dot: {
    type: Number,
    default: 0,
  },
  badge: {
    type: Number,
    default: 0,
  },
  bg: {
    type: String,
    default: 'bg-primary',
  },
  position: {
    type: String,
    default: 'sticky',
  },
  shadow: {
    type: String,
    default: 'shadow-md',
  },
})
</script>

<template>
  <header class="nav-bar left-0 right-0 top-0 z-[9999] h-[44px] px-[16px]" :class="[bg, position, shadow]">
    <div class="relative h-full">
      <div class="left-arrow absolute left-0 top-1/2 -translate-y-1/2 text-base text-base-100">
        <slot name="start">
          <button @click="router.back" class="flex items-center">
            <svg-icon name="back" className="h-[24px] w-[24px]"></svg-icon>
          </button>
<!--          <img class="h-[24px] w-[24px]" src="@/assets/image/login/arrow-left.png" alt="" @click="router.back" />-->
        </slot>
      </div>
      <div class="default flex h-full items-center justify-center text-center text-[16px] text-base-100">
        <slot name="title">{{ title || route.meta.title }}</slot>
      </div>
      <div class="absolute right-0 top-1/2 -translate-y-1/2">
        <slot name="end"></slot>
      </div>
    </div>
  </header>
</template>

<style scoped></style>
