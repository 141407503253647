import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import setPageTitle from '@/utils/set-page-title.js'
import { useAppStoreHook } from '@/stores/modules/app.js'
import { useNoCachedViewStoreHook } from '@/stores/modules/cachedView.js'
import  {isMobile} from '@/utils/index.js'

/**
 *
 * redirect: noRedirect           如果设置为noRedirect，不会重定向
 * name:'router-name'             名称用于 <keep-alive> （必须设置！！！）
 * meta : {
    roles: ['admin','editor']    控制页面的角色（可以设置多个角色）
    title: 'title'               在页面标题和navbar导航中显示的名称（建议设置）
    alwaysShowNavbar: false      如果设置为false，navbar将在页面中隐藏（默认是true）
    alwaysShowTabbar: false      如果设置为false，tabbar将在页面中隐藏（默认是true）
    noCache: true                是否开启缓存,组件默认开启缓存
    depth: 0,                    用于控制tabbar选择切换动画
  }
 */

/**
 * constantRoutes
 * 不需要权限要求的基础页面
 * 所有角色都可以访问
 */

const constantRoutes = [
  {
    path: '/',
    redirect: '/login/index',
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/index',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          depth: 0,
          alwaysShowNavbar: false,
          alwaysShowTabbar: true,
          title: '',
          keepAlive: true,
        },
      },
      {
        path: '/nearby',
        name: 'Nearby',
        component: () => import('@/views/Nearby/index.vue'),
        meta: {
          depth: 1,
          alwaysShowNavbar: false,
          alwaysShowTabbar: true,
          title: '',
          keepAlive: true,
        },
      },
      {
        path: '/messages',
        name: 'Messages',
        component: () => import('@/views/Messages/index.vue'),
        meta: {
          depth: 2,
          alwaysShowNavbar: false,
          alwaysShowTabbar: true,
          title: '',
          keepAlive: true,
        },
      },
      {
        path: '/me',
        name: 'Me',
        component: () => import('@/views/Me/index.vue'),
        meta: {
          depth: 3,
          alwaysShowNavbar: false,
          alwaysShowTabbar: true,
          title: '',
          keepAlive: true,
        },
      },
    ],
  },
  //  动态详情
  {
    path: '/activity-detail',
    name: 'ActivityDetail',
    component: () => import('@/views/Activity/_index.vue'),
    meta: {
      depth: 2,
      noCache: true,
      title: '',
    },
  },
  //   搜索页动态详情
  {
    path: '/trend-detail/:trendsId',
    props: true,
    name: 'TrendDetail',
    component: () => import('@/views/Activity/_index1.vue'),
    meta: {
      depth: 2,
      noCache: true,
      title: '动态详情',
    },
  },
  //  个人主页
  {
    path: '/userprofile/:userId',
    name: 'UserProfile',
    component: () => import('@/views/UserProfile/index.vue'),
    props: true,
    meta: {
      depth: 10,
      noCache: true,
      title: '',
    },
  },
  {
    path: '/search/result',
    name: 'searchResult',
    component: () => import('@/views/Search/result.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search/index.vue'),
    meta: {
      depth: 2,
      noCache: true,
      title: '',
    },
  },
  // 这个通配符路由将捕获所有其他 /search/* 路径
  {
    path: '/search/:path(.*)',
    redirect: 'search',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login.vue'),
    redirect: '/login/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/Login/index.vue'),
      },
      {
        path: 'phoneLogin',
        component: () => import('@/views/Login/phoneLogin.vue'),
      },
      {
        path: 'improve',
        component: () => import('@/views/Login/improveInfo.vue'),
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: '/settings/list',
    component: () => import('@/views/Settings/index.vue'),
    children: [
      {
        path: 'list',
        component: () => import('@/views/Settings/children/list.vue'),
        meta: { title: '设置' },
      },
      {
        path: 'account',
        component: () => import('@/views/Settings/children/account.vue'),
        meta: {
          title: '账号与安全',
        },
      },
      {
        path: 'bindPhone',
        component: () => import('@/views/Settings/children/bindPhone.vue'),
        meta: {
          title: '手机绑定',
        },
      },
      {
        path: 'editPassword',
        component: () => import('@/views/Settings/children/editPassword.vue'),
        meta: {
          title: '修改密码',
        },
      },
      {
        path: 'resetPassword',
        component: () => import('@/views/Settings/children/resetPassword.vue'),
        meta: {
          title: '重置密码',
        },
      },
      {
        path: 'cancelAccount',
        component: () => import('@/views/Settings/children/cancelAccount.vue'),
        meta: {
          title: '注销账号',
        },
      },
      {
        path: 'notification',
        component: () => import('@/views/Settings/children/notification.vue'),
        meta: {
          title: '新消息通知',
        },
      },
      {
        path: 'privacy',
        component: () => import('@/views/Settings/children/privacy.vue'),
        meta: {
          title: '隐私设置',
        },
      },
      {
        path: 'searchUser',
        component: () => import('@/views/Settings/children/searchUser.vue'),
        meta: {
          title: '对指定的人隐身',
        },
      },
      {
        path: 'friendship',
        component: () => import('@/views/Settings/children/friendship.vue'),
        meta: {
          title: '交友设置',
        },
      },
      {
        path: 'blacklist',
        component: () => import('@/views/Settings/children/blacklist.vue'),
        meta: {
          title: '黑名单',
        },
      },
      {
        path: 'about',
        component: () => import('@/views/Settings/children/about.vue'),
        meta: {
          title: '关于我们',
        },
      },
    ],
  },
  {
    path: '/myinfo',
    component: () => import('@/views/Me/userinfo/myInfo.vue'),
  },
  {
    path: '/editInfo',
    component: () => import('@/views/Me/userinfo/editInfo.vue'),
    meta: {
      title: '编辑资料',
    },
  },
  {
    path: '/attention',
    component: () => import('@/views/Me/attention/like.vue'),
  },
  {
    path: '/visitor',
    component: () => import('@/views/Me/attention/visit.vue'),
    meta: {
      title: '访客记录',
      keepAlive: true, 
    },
  },
  {
    path: '/member',
    component: () => import('@/views/Me/member/index.vue'),
    meta: {
      title: '会员中心',
    },
  },
  {
    path: '/myAlbum',
    component: () => import('@/views/Me/myAlbum/albumList.vue'),
    meta: {
      title: '我的相册',
    },
  },
  {
    path: '/handleAlbum',
    component: () => import('@/views/Me/myAlbum/handleAlbum.vue'),
  },
  {
    path: '/wallet',
    component: () => import('@/views/Me/wallet/index.vue'),
    meta: {
      title: '我的钱包',
    },
  },
  {
    path: '/bill',
    component: () => import('@/views/Me/wallet/src/bill.vue'),
    meta: {
      title: '账单',
    },
  },
  {
    path: '/authentication',
    component: () => import('@/views/Me/authentication/index.vue'),
    meta: {
      title: '认证中心',
    },
  },
  {
    path: '/startAuthentication',
    component: () => import('@/views/Me/authentication/startAuthentication.vue'),
    meta: {
      title: '认证中心',
    },
  },
  {
    path: '/authFailed',
    component: () => import('@/views/Me/authentication/authFailed.vue'),
    meta: {
      title: '认证中心',
    },
  },
  {
    path: '/authSuccess',
    component: () => import('@/views/Me/authentication/authSuccess.vue'),
    meta: {
      title: '认证中心',
    },
  },
  {
    path: '/myDynamic',
    component: () => import('@/views/Me/myDynamic/index.vue'),
    meta: {
      title: '我的动态',
    },
  },
  {
    path: '/message/chat',
    component: () => import('@/views/Messages/src/chat.vue'),
  },
  {
    path: '/message/chatDetail',
    component: () => import('@/views/Messages/src/chatDetail.vue'),
    meta: {
      title: '聊天详情',
    },
  },
  {
    path: '/message/report',
    component: () => import('@/views/Messages/src/report.vue'),
    meta: {
      title: '举报',
    },
  },
  {
    path: '/publishDynamic',
    component: () => import('@/views/publishDynamic/index.vue'),
    meta: {
      title: '发布动态',
    },
  },
  {
    path: '/addTopic',
    component: () => import('@/views/publishDynamic/addTopic.vue'),
    meta: {
      title: '添加话题',
    },
  },
  {
    path: '/nearby/ranking',
    component: () => import('@/views/Nearby/src/ranking.vue'),
  },
  {
    path: '/payStatus',
    component: () => import('@/components/Popup/payStatus.vue'), 
    meta: {
      title: '交易结果',
    },
  },
  {
    path: '/pc',
    component: () => import('@/views/PC/index.vue'),  
  },

]
const routes = [...constantRoutes]
const router = createRouter({
  // history: createWebHistory('/chart/'),
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   // 包含tabs组件的页面使用的非页面滚动
  //   const containTabsPage = ['/index', '/nearby', '/messages', '/me']
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { top: 0 }
  //   }
  // },
})

router.beforeEach((to, from) => {
  console.log(isMobile(), '----isMobile');
 
  if (!isMobile() && to.path !== '/pc') {
    return router.replace({ path: '/pc' });
  }
 
  if (isMobile() && to.path === '/pc') {
    return router.replace({ path: '/' }); 
  }
 
  return true; 
})

router.afterEach((to, from, failure) => {
 
  // 缓存路由
  console.log('to', to)
  useNoCachedViewStoreHook().addNoCachedView(to)
  // 页面 title
  setPageTitle(to.meta.title)
})
export default router
