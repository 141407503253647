import { defineStore } from 'pinia'
import { store } from '@/stores'

export const useNoCachedViewStore = defineStore({
  id: 'no-cached-view',
  state: () => ({
    // 不进行缓存的页面 keepAlive
    noCachedViewList: [],
  }),
  actions: {
    addNoCachedView(view) {
      // 不重复添加
      if (this.noCachedViewList.includes(view.name)) return
      // if (!view?.meta?.noCache) {
      //   this.noCachedViewList.push(view.name)
      // }
      if (view?.meta?.noCache) {
        this.noCachedViewList.push(view.name)
      }
    },
    delCachedView(view) {
      const index = this.noCachedViewList.indexOf(view.name)
      index > -1 && this.noCachedViewList.splice(index, 1)
    },
    delOtherCachedViews(view) {
      const index = this.noCachedViewList.indexOf(view.name)
      if (index > -1) {
        this.noCachedViewList = this.noCachedViewList.slice(index, index + 1)
      } else {
        // if index = -1, there is no cached tags
        this.noCachedViewList = []
      }
    },
    delAllCachedViews() {
      this.noCachedViewList = []
    },
  },
})

export function useNoCachedViewStoreHook() {
  return useNoCachedViewStore(store)
}
