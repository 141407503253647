import { http ,http2} from '@/utils/request/index.js' 

/**
 * 百度的接口 
 * 
 */
export function getAccessToken(clientId, clientSecret) {
  return http2.request({
      url: `/oauth/2.0/token?grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`,
      method: 'post', 
  })
} 
export function getVerifyToken(data,token) {
  return http2.request({
      url: '/rpc/2.0/brain/solution/faceprint/verifyToken/generate?access_token='+token,
      method: 'post',
      data,
  })
} 
export function uploadMatchImage(data,token) {
  return http2.request({
      url: '/rpc/2.0/brain/solution/faceprint/uploadMatchImage?access_token='+token,
      method: 'post',
      data,
  })
} 
export function resultSimple(data,token) {
  return http2.request({
      url: '/rpc/2.0/brain/solution/faceprint/result/simple?access_token='+token,
      method: 'post',
      data,
  })
} 


/**
 * 自己的接口
 *  
 */

// 真人认证百度上传接口
export function uploadFace(data,) {
  return http.request({
      url: '/api/thrid/baidu/uploadFace',
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
  })
}

// 百度认证结果图片查询
export function queryFace(data,) {
  return http.request({
      url: '/api/thrid/baidu/queryFace',
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
  })
}