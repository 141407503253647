import { http } from '@/utils/request/index.js'

// 获取职业、省市区、标签基本信息
export function getUserImproveBaseInfo(data) {
  return http.request({
    url: '/api/appIndex/getUserImproveBaseInfo',
    method: 'post',
    data,
  })
}

// 获取获取省市区
export function getAllAreaList(data) {
  return http.request({
    url: '/api/appIndex/getAllAreaList',
    method: 'post',
    data,
  })
}

// 用户头像上传
export function biopsyCheckUpload(data) {
  return http.request({
    url: '/api/common/biopsyCheckUpload',
    method: 'post',
    data,
  })
}

// 首次登录完善信息
export function improveUserInfo(data) {
  return http.request({
    url: '/api/appIndex/improveUserInfo',
    method: 'post',
    data,
  })
}

// 我的主页
export function queryMyPersonInfo(data) {
  return http.request({
    url: '/api/app/person/queryMyPersonInfo',
    method: 'post',
    data,
  })
}

// 用户详情
export function getUserDetail(data) {
  return http.request({
    url: '/api/app/user/getUserDetail',
    method: 'post',
    data,
  })
}

// 用户主页相册
export function getUserAlbum(data) {
  return http.request({
    url: '/api/app/user/queryUserAlbumList',
    method: 'post',
    data,
  })
}

// 编辑资料详情
export function queryImproveUserInfo(data) {
  return http.request({
    url: '/api/app/person/queryImproveUserInfo',
    method: 'post',
    data,
  })
}

// 编辑资料保存
export function personImproveUserInfo(data) {
  return http.request({
    url: '/api/app/person/improveUserInfo',
    method: 'post',
    data,
  })
}

// 喜欢我的/我喜欢的
export function queryUserFollowList(data) {
  return http.request({
    url: '/api/app/person/queryUserFollowList',
    method: 'post',
    data,
  })
}

// 访客记录
export function queryUserVisitRecord(data) {
  return http.request({
    url: '/api/app/person/queryUserVisitRecord',
    method: 'post',
    data,
  })
}

// 取消喜欢
export function cancelUserFollow(data) {
  return http.request({
    url: '/api/app/trends/cancelUserFollow',
    method: 'post',
    data,
  })
}

// 喜欢Ta
export function addUserFollow(data) {
  return http.request({
    url: '/api/app/trends/addUserFollow',
    method: 'post',
    data,
  })
}

//  上传相册图片返回url
export function batchUploadFile(data) {
  return http.request({
    url: '/api/common/batchUploadFile',
    method: 'post',
    data,
  })
}

// 上传相册
export function addUserAlbum(data) {
  return http.request({
    url: '/api/app/person/addUserAlbum',
    method: 'post',
    data,
  })
}

// // 设置背景图片
export function userBackgroundPicture(data) {
  return http.request({
    url: '/api/app/person/userBackgroundPicture',
    method: 'post',
    data,
  })
}

// 认证中心状态
export function queryGoddessStatus(data) {
  return http.request({
    url: '/api/app/person/queryGoddessStatus',
    method: 'post',
    data,
  })
}

// 我的相册列表
export function queryUserAlbumList(data) {
  return http.request({
    url: '/api/app/user/queryUserAlbumList',
    method: 'post',
    data,
  })
}

// 删除相册
export function batchDeleteUserAlbum(data) {
  return http.request({
    url: '/api/app/person/batchDeleteUserAlbum',
    method: 'post',
    data,
  })
}

// 相册阅后即焚开关
export function changeAlbumType(data) {
  return http.request({
    url: '/api/app/person/changeAlbumType',
    method: 'post',
    data,
  })
}

// 相册排序
export function orderUserAlbum(data) {
  return http.request({
    url: '/api/app/person/orderUserAlbum',
    method: 'post',
    data,
  })
}

// 动态点赞/取消点赞
export function addTrendsPraise(data) {
  return http.request({
    url: '/api/app/trends/addTrendsPraise',
    method: 'post',
    data,
  })
}

// 他人主页点赞
export function addUserPraiseOnProfile(data) {
  return http.request({
    url: '/api/app/user/addUserPraise',
    method: 'post',
    data,
  })
}
// 动态是否仅自己可见
export function addTrendsOnlyOwn(data) {
  return http.request({
    url: '/api/app/trends/addTrendsOnlyOwn',
    method: 'post',
    data,
  })
}

// 删除动态
export function deleteTrends(data) {
  return http.request({
    url: '/api/app/trends/deleteTrends',
    method: 'post',
    data,
  })
}

// 收到的礼物总数
export function queryRewardGiftTotalNumByUserId(data) {
  return http.request({
    url: '/api/app/gift/queryRewardGiftTotalNumByUserId',
    method: 'post',
    data,
  })
}

// 默认的交友宣言
export function queryIntroduceTips(data) {
  return http.request({
    url: '/api/appIndex/queryIntroduceTips',
    method: 'post',
    data,
  })
}

// 真人认证
export function authRealStatusNew(data) {
  return http.request({
    url: '/api/app/person/authRealStatusNew',
    method: 'post',
    data,
  })
}

// 颜值认证
export function authGoddessStatus(data) {
  return http.request({
    url: '/api/app/person/authGoddessStatus',
    method: 'post',
    data,
  })
}

// 获取举报原因
export function getReportReasons(data) {
  return http.request({
    url: '/api/app/trends/queryReportInfo',
    method: 'post',
    data,
  })
}
// 不感兴趣某条动态
export function setAsNotInterested(data) {
  return http.request({
    url: '/api/app/trends/addNotInterestTrends',
    method: 'post',
    data,
  })
}
//举报某条动态
export function reportPost(data) {
  return http.request({
    url: '/api/app/trends/addTrendsReport',
    method: 'post',
    data,
  })
}
