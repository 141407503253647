<script setup>
import { computed, reactive, onMounted ,onActivated} from 'vue'
import { useAppStoreHook } from '@/stores/modules/app.js'
import { useDarkMode } from '@/hooks/useToggleDarkMode.js'
import { BASE_URL } from '@/config'
import { setInnerW } from '@/utils'
import {useUserStore} from '@/stores/modules/user.js'

const userStore = useUserStore()

function resetVhAndPx() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  //document.documentElement.style.fontSize = document.documentElement.clientWidth / 375 + 'px'
}

/*解决使用沉浸式浏览时，内容溢出视口*/
onMounted(() => {
  resetVhAndPx()
  // 监听resize事件 视图大小发生变化就重新计算1vh的值
  window.addEventListener('resize', () => {
    location.href = location.href || BASE_URL + '/'
    resetVhAndPx()
    setInnerW('app')
  })

  setInnerW('app')
  console.log(  setInnerW('app'));
})
const isDarkMode = computed(() => useDarkMode())


</script>

<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>

<style scoped></style>
