import { render, createVNode, ref, reactive } from 'vue'
import dialog from '@/components/Dialog/genericDialog.vue'

export default {
  install: (app) => {
    // 添加全局方法 $vipDialog
    app.config.globalProperties.$dialog = (options) => {
      console.log(options)
      const show = ref(true)
      const wrapper = document.createElement('div')
      wrapper.id = 'ge-dialog'

      const close = () => {
        show.value = false
        setTimeout(() => {
          if (wrapper.parentNode) {
            wrapper.parentNode.removeChild(wrapper)
          }
        }, 300)
      }

      const dialogProps = reactive({
        show: show.value,
        close: close,
        ...options,
      })
      // console.log("🚀 ~ dialogProps:", dialogProps)

      const vnode = createVNode(dialog, dialogProps)
      render(vnode, wrapper) // 渲染组件
      document.body.appendChild(wrapper) // 添加到 body

      return {
        close,
      }
    }
  },
}
