/**
 ** Hex color to RGBA color
 */
export const hexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * @description 获取queryString
 */
export const getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}

/**
 * @description isIos
 */
export const isIos = () => {
  const u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

/**
 * @description 字符串获取时间戳，兼容ios
 */
export const getDate = (date) => {
  return new Date(date.replace(/-/g, '/'))
}

export const toPercent = (val) => {
  return `${(Math.abs(val) * 100).toFixed(2)}%`
}

export const copyMsg = (msg) => {
  navigator.clipboard.writeText(msg)
}

/**
 * @description: 计算星座
 *
 * @param {*Array} birthdateArray - 出生日期数组，格式为['年', '月', '日']
 * @returns {String} - 返回星座名称
 */
export const getZodiacSign = (birthdateArray) => {
  const [year, month, day] = birthdateArray.map(Number)
  const zodiacs = [
    { name: '水瓶座', start: [1, 20], end: [2, 18] },
    { name: '双鱼座', start: [2, 19], end: [3, 20] },
    { name: '白羊座', start: [3, 21], end: [4, 19] },
    { name: '金牛座', start: [4, 20], end: [5, 20] },
    { name: '双子座', start: [5, 21], end: [6, 21] },
    { name: '巨蟹座', start: [6, 22], end: [7, 22] },
    { name: '狮子座', start: [7, 23], end: [8, 22] },
    { name: '处女座', start: [8, 23], end: [9, 22] },
    { name: '天秤座', start: [9, 23], end: [10, 23] },
    { name: '天蝎座', start: [10, 24], end: [11, 21] },
    { name: '射手座', start: [11, 22], end: [12, 21] },
    { name: '摩羯座', start: [12, 22], end: [1, 19] },
  ]
  for (let i = 0; i < zodiacs.length; i++) {
    const z = zodiacs[i]
    if ((month === z.start[0] && day >= z.start[1]) || (month === z.end[0] && day <= z.end[1])) {
      return z.name
    }
  }

  // 如果没有找到，返回第一个星座（通常不会发生）
  return zodiacs[0].name
}

/**
 * @description: 计算年龄
 *
 * @param {*Array} birthdateArray - 出生日期数组，格式为['年', '月', '日']
 * @returns {Number} - 返回年龄
 */
export const calculateAge = (birthdateArray) => {
  const [year, month, day] = birthdateArray.map(Number) // 将字符串转换为数字
  const birthdate = new Date(year, month - 1, day) // 月份从0开始
  const today = new Date()

  let age = today.getFullYear() - birthdate.getFullYear()
  const m = today.getMonth() - birthdate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--
  }

  return age
}

/**
 * @description: 生成身高体重选项数组
 *
 * @param {Number} start - 开始值
 * @param {Number} end - 结束值
 * @param {String} unit    - 单位
 * @return {Array}    - 返回选项数组
 */
export const generateArray = (start, end, unit) => {
  const result = []
  for (let i = start; i <= end; i++) {
    let str = i < 10 ? `0${i}` : `${i}`
    result.push({
      text: `${str}${unit}`,
      value: `${str}${unit}`,
    })
  }
  return result
}
// 元素左上角相对于整张网页的坐标
export const getElementPosition = (e) => {
  console.log('getElementPosition')
  var x = 0
  var y = 0
  while (e !== null) {
    x += e.offsetLeft
    y += e.offsetTop
    e = e.offsetParent
  }
  return { x: x, y: y }
}

//  el需要传标签id
export const setInnerW = (el) => {
  let bottomInset = window.getComputedStyle(document.documentElement).getPropertyValue('--bottom-safe-area1');
	if (!bottomInset) {
		bottomInset = window.getComputedStyle(document.documentElement).getPropertyValue('--bottom-safe-area2');
	}
	console.log(bottomInset,'----bottomInset---',window.innerHeight);
	bottomInset = parseFloat(bottomInset) 
	if (isNaN(bottomInset)) {
		bottomInset = 0
	}  
	document.getElementById(el).style.height = `${window.innerHeight - Number(bottomInset)}px`
  return `${window.innerHeight - Number(bottomInset)}px`
}

// 判断是否是移动设备
export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 匹配常见移动设备的User-Agent字符串，但不包括平板
  if (/Android/i.test(userAgent) && !/Android.*Mobile/.test(userAgent)) {
    return false; // 排除非'Mobile'标记的Android设备，通常是平板
  }

  if (/iPhone/i.test(userAgent)) {
    return true; // iPhone是手机
  }

  if (/iPad|Windows Phone|Tablet|WebApp|IEMobile|BlackBerry|PlayBook|BB10|Opera Mini|Mobile Safari/i.test(userAgent)) {
    return false; // 排除平板和其他非手机设备
  }

  return /Mobi/i.test(userAgent); // 最后检查是否包含'Mobi'，这是很多移动浏览器的标识
}
