import { http } from '@/utils/request/index.js'
// 获取登录验证码(登录)
export function sendPostLoginCaptcha(data) {
  return http.request({
    url: '/api/appIndex/sendPostLoginCaptcha',
    method: 'post',
    data,
  })

}

// 验证码登录
export function loginByCaptcha(data) {
  return http.request({
    url: '/net/api/appIndex/phoneMessageLogin',
    method: 'post',
    data,
  })

}

// 密码登录
export function loginByPassword(data) {
  return http.request({
    url: '/net/api/appIndex/userLogin',
    method: 'post',
    data,
  })

}

// 获取登录验证码(忘记密码)
export function sendPostForgetCaptcha(data) {
  return http.request({
    url: '/api/appIndex/sendPostForgetCaptcha',
    method: 'post',
    data,
  })

}

// 校验忘记密码的验证码
export function forgetCaptchaCheck(data) {
  return http.request({
    url: '/api/appIndex/forgetCaptchaCheck',
    method: 'post',
    data,
  })

}

// 保存新密码
export function forgetLogin(data) {
  return http.request({
    url: '/api/appIndex/forgetLogin',
    method: 'post',
    data,
  })

}

// 获取当前版本设备类型（登录/注册必传）
export function getCurrentUserVersion(data) {
  return http.request({
    url: '/api/app/user/getCurrentUserVersion',
    method: 'post',
    data,
  })

}