<template>
	<van-popup v-bind="$attrs" @click-overlay="clickOverlay">
		<slot name="header" v-if="headerShow">
			<div
				:class="['relative py-[16px] flex justify-center items-center text-base', { 'border-b border-b-[#42405b]': props.headerBorder }]">
				<span>{{ title }}</span>
				<button v-if="closeable" class="absolute top-[8px] right-[16px] text-[28px]" @click="hide">
					&times;
				</button>
			</div>
		</slot>
		<div class="px-[16px]">
			<slot></slot>
		</div>
	</van-popup>
</template>

<script setup>
import { ref, watch, computed, nextTick } from 'vue';

/**
 * 弹出层组件
 *     
 * @param {Boolean} closeable - 是否显示关闭按钮
 * @param {String} title - 弹出层标题
 * @param {Boolean} headerBorder - 是否显示标题栏底部边框  
 * 
 * @event update:show - 更新show的值 
 */
const props = defineProps({
	closeable: {
		type: Boolean,
		default: true
	},
	title: {
		type: String,
		default: '提示'
	},
	headerBorder: {
		type: Boolean,
		default: true
	},
	headerShow: {
		type: Boolean,
		default: true
	},
	closeOnClickOverlay: {
		type: Boolean,
		default: true
	}
});


const emit = defineEmits(['update:show',]);

const hide = () => {
	emit('update:show', false);
}

const clickOverlay = () => { 
	if (props.closeOnClickOverlay) {
		hide();
	}
}
</script>

<style scoped>  
</style>