import Axios from 'axios';
import { ContentTypeEnum, ResultEnum } from '@/enums/requestEnum.js';
import { showToast, showLoadingToast, closeToast } from 'vant';
import { useUserStore } from '@/stores/modules/user';
import { load } from '@/lib/loadingPlugin.js';
import router from '@/router/index';

let loadingCounter = 0;

// 默认 axios 实例请求配置
const configDefault = {
  headers: {
    'Content-Type': ContentTypeEnum.FORM_URLENCODED,
  },
  timeout: 30000,
  baseURL: import.meta.env.VITE_BASE_API,
  data: {},
};

const configDefault2 = {
  headers: {
    'Content-Type': ContentTypeEnum.JSON,
  },
  timeout: 30000,
  baseURL:  import.meta.env.VITE_BAIDU_API,
  data: {},
};

const configDefault3 = {
  headers: {
    'Content-Type': ContentTypeEnum.FORM_URLENCODED,
  },
  timeout: 30000,
  baseURL: import.meta.env.VITE_PAY_APT,
  data: {},
};

class Http {
  // 当前实例的 axios 实例
  axiosInstance;

  constructor(config) {
    this.axiosInstance = Axios.create(config); // 每个实例有自己的 axios 实例
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  // 请求拦截
  httpInterceptorsRequest() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        manageLoading(true, config);
        setHeaders(config);
        return config;
      },
      (error) => {
        showToast(error.message);
        return Promise.reject(error);
      },
    );
  }

  // 响应拦截
  httpInterceptorsResponse() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        manageLoading(false, response.config); // 关闭加载提示
        return handleResponse(response);
      },
      (error) => {
        manageLoading(false, error.response?.config || error.config);
        // handleError(error);
        return Promise.reject(error);
      },
    );
  }

  // 通用请求函数
  request(paramConfig) {
    const config = { ...this.axiosInstance.defaults, ...paramConfig };
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

function manageLoading(isLoading, config) {
  if (!config) return;
  // 是否请求时显示 loading 效果，默认显示，指定 isNotLoading 为true 则不显示
  if (isLoading && !config.isNotLoading) {
    loadingCounter++;
    load.show('请稍后...');
  } else if (!isLoading && !config.isNotLoading) {
    loadingCounter--;
    if (loadingCounter <= 0) {
      loadingCounter = 0;
      load.hide();
    }
  }
}

// 设置请求头
function setHeaders(config) {
  const userStore = useUserStore();
  const token = userStore.token || '';
  if (token) {
    config.headers['x-access-token'] = token;
  }
  if (config.method === 'post' && config.data instanceof FormData) {
    config.headers['Content-Type'] = ContentTypeEnum.FORM_DATA;
  }
  config.headers['version'] = import.meta.env.VITE_APP_VERSION;
}

// 处理响应
function handleResponse(response) { 
  const userStore = useUserStore();
  const { code, msg,message ,success} = response.data;
  if (code === ResultEnum.SUCCESS || success) {
    return response.data;
  }
  if (code === ResultEnum.UNAUTHORIZED) {
    userStore.logoutAction()
  }
  // 完善信息取消提示语
  if(code !== 301){
    showToast(msg || message);
  }
  return Promise.reject(response.data);
}

function handleError(error) {
  const status = error.response?.status;
  let message = '网络连接故障';
  switch (status) {
    case 400:
      message = '请求错误';
      break;
    case 401:
      message = '未授权，请登录';
      break;
    case 403:
      message = '拒绝访问';
      break;
    case 404:
      message = `请求地址出错: ${error.response?.config?.url}`;
      break;
    case 408:
      message = '请求超时';
      break;
    case 500:
      message = '服务器内部错误';
      break;
    case 501:
      message = '服务未实现';
      break;
    case 502:
      message = '网关错误';
      break;
    case 503:
      message = '服务不可用';
      break;
    case 504:
      message = '网关超时';
      break;
    case 505:
      message = 'HTTP版本不受支持';
      break;
  }
  showToast(message);
}

export const http = new Http(configDefault);
export const http2 = new Http(configDefault2);
export const http3 = new Http(configDefault3);