import { http } from '@/utils/request/index.js'

// 我的会员信息
export function myVipDetail(data) {
  return http.request({
    url: '/api/app/vip/vipDetail',
    method: 'post',
    data, 
  })

}

// 会员特权列表
export function queryVipPrivilegeShowList(data) {
  return http.request({
    url: '/api/app/vip/queryVipPrivilegeShowList',
    method: 'post',
    data, 
  })

}

// 会员价格列表
export function queryVipPriceList(data) {
  return http.request({
    url: '/api/app/vip/queryVipPriceList',
    method: 'post',
    data, 
  })

}

// 会员描述列表
export function queryVipDescribeList(data) {
  return http.request({
    url: '/api/app/vipDescribe/list',
    method: 'post',
    data, 
  })

}

//  价格详情
export function queryVipInfoById(data) {
  return http.request({
    url: '/api/app/vip/queryVipInfoById',
    method: 'post',
    data, 
  })

}

// 提交订单
export function rechargeVip(data) {
  return http.request({
    url: '/api/app/vip/rechargeVip',
    method: 'post',
    data, 
  })

}
