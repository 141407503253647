import { createApp } from 'vue'
import router from './router'
import { store } from './stores'
import '@/lib/flexible.js'
import dayjs from "dayjs";
import '@/assets/styles/reset.css'
import '@/assets/styles/tailwind.css'
import 'vant/lib/toast/style'
import { createHead } from 'unhead'
import registerGlobalComponents from './lib/globalComponents'
import V3waterfall from 'v3-waterfall'
import 'v3-waterfall/dist/style.css'
import vipDialogPlugin from './lib/vipDialogPlugin';
import dialog from '@/lib/dialog.js'
import longPressDirective from '@/lib/longPressDirective.js'
console.log('import.meta.env.VITE_MODE',import.meta.env.VITE_MODE)

// dayjs扩展功能插件
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

// svg icon
import 'virtual:svg-icons-register'
import App from './App.vue'

if (window.matchMedia('(prefers-color-scheme: dark)').matches)
  document.documentElement.setAttribute('data-theme', 'dark')
else document.documentElement.setAttribute('data-theme', 'light')

createHead()
const app = createApp(App)
app.directive('longpress', longPressDirective);
registerGlobalComponents(app)
app.provide('dayjs', dayjs); //便于在js部分可直接用
app.config.globalProperties.$dayjs = dayjs;
app.use(router)
app.use(store) 
app.use(V3waterfall)
app.use(vipDialogPlugin);
app.use(dialog);
app.mount('#app')
