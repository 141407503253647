// ftConfirmPlugin.js
import { render, createVNode, ref, reactive } from 'vue';
import confirm from '@/components/Dialog/openVipDialog.vue';

export default {
  install: (app) => {
    // 添加全局方法 $vipDialog
    app.config.globalProperties.$vipDialog = (options) => {
      console.log(options);
      const show = ref(true);
      const wrapper = document.createElement('div');
      wrapper.id = 'ft-confirm';  

      const close = () => {
        show.value = false;
        setTimeout(() => {
          if (wrapper.parentNode) {
            wrapper.parentNode.removeChild(wrapper);
          }
        }, 300);  
      };

      const dialogProps = reactive({
        show: show.value,
        close: close,  
        ...options
      });
      console.log("🚀 ~ dialogProps:", dialogProps)

      const vnode = createVNode(confirm, dialogProps);
      render(vnode, wrapper); // 渲染组件
      document.body.appendChild(wrapper); // 添加到 body

      return {
        close,  
      };
    };
  }
};