<template>
	<Popup v-model:show="show" position="bottom" round @open="getVipInfoById">
		<template #header>
			<div class="flex flex-col justify-center items-center gap-[0px] text-sm text-[#FFDFBE] pt-[16px]">
				<div class="text-base">支付确认</div>
				<div>
					<span class="text-[24px]">￥</span> <span class="text-[34px]">{{vipInfo.openMoney}}</span>
				</div>
				<div class="flex gap-[4px] items-center mb-[4px]">
					<span>{{vipInfo.myIntegral}}</span>
					<img class="w-[16px] h-[16px] mt-[-5px]" src="@/assets/image/my/gold.png" alt="">
				</div>
				<span>{{vipInfo.operation}}</span>
			</div>
			<van-radio-group v-model="paytType">
				<van-cell-group inset>
					<van-cell title="微信支付" clickable @click="paytType = '2'">
						<template #title>
							<div class="flex items-center gap-[4px]">
								<img class="w-[22px] h-[22px]" src="@/assets/image/icon/wechat-pay.png" alt="">
								<span>微信支付</span>
							</div>
						</template>
						<template #right-icon>
							<van-radio name="2" />
						</template>
					</van-cell>

					<van-cell title="支付宝支付" clickable @click="paytType = '1'">
						<template #title>
							<div class="flex items-center gap-[4px]">
								<img class="w-[22px] h-[22px]" src="@/assets/image/icon/ali-pay.png" alt="">
								<span>支付宝支付</span>
							</div>
						</template>
						<template #right-icon>
							<van-radio name="1" />
						</template>
					</van-cell>
					<van-cell title="钱包支付" clickable @click="paytType = '4'">
						<template #title>
							<div class="flex items-center gap-[4px]">
								<img class="w-[16px] h-[16px] " src="@/assets/image/my/gold.png" alt="">
								<span>钱包支付（余额:{{vipInfo.myIntegral}}颜值币）</span>
							</div>
						</template>
						<template #right-icon>
							<van-radio name="4" />
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
			<div class="px-[16px] my-[30px]">
				<van-button block round type="primary" @click="submit">确认支付</van-button>
			</div>
		</template>
	</Popup>
</template>

<script setup>
import { ref, defineProps,defineEmits } from 'vue'
import { queryVipInfoById, rechargeVip } from '@/api/member/index'

const vipInfo = ref({
	myIntegral: 0,
	openMoney: 0,
	operation: ''
})

const show = ref(false)

const props = defineProps({
	activeId: {
		type: Number,
		default: 0
	}
})

const emit = defineEmits(['paySuccess'])

const getVipInfoById = async () => {
	const res = await queryVipInfoById({ vipPriceId: props.activeId })
	vipInfo.value = res.data 
}
const paytType = ref('2')

const submit = async () => {
	const { operation } = vipInfo.value
	const res = await rechargeVip({ operation, openType: paytType.value, vipPriceId: props.activeId })
	console.log("🚀 ~ submit ~ res:", res)
	show.value = false
		emit('paySuccess')
	if (res.code === 0 && res.msg === '开通会员成功') {
		show.value = false
		emit('paySuccess')
	}
}

const close = () => {
	show.value = false
}
const open = () => {
	show.value = true
}

defineExpose({
	close,
	open
})

</script>

<style  scoped>
:deep(.van-radio__icon--checked .van-icon){
  color: #333;
}
</style>