import { defineStore } from 'pinia'
import { store } from '@/stores'

const darkModeKey = '__dark_mode__'
const isDarkMode = () => {
  const darkMode = window.localStorage.getItem(darkModeKey)
  if (darkMode) {
    // 先判断是否手动设置深色模式
    return darkMode === 'true'
  } else {
    // 没有再根据系统设置判断
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  }
}

export const useDarkModeStore = defineStore({
  id: 'dark-mode',
  state: () => ({
    darkMode: isDarkMode(),
  }),
  actions: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode
      if (this.darkMode) {
        document.documentElement.setAttribute('data-theme', 'dark')
        window.localStorage.setItem(darkModeKey, 'true')
      } else {
        document.documentElement.setAttribute('data-theme', 'light')
        // document.documentElement.setAttribute('data-theme', 'dark')
        window.localStorage.setItem(darkModeKey, 'false')
      }
    },
  },
})

export function useDarkModeStoreHook() {
  return useDarkModeStore(store)
}
