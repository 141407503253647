import { http } from '@/utils/request/index.js'

// 获取验证码（修改绑定手机号）
export function sendPostChangeCaptcha(data) {
  return http.request({
    url: '/api/appIndex/sendPostChangeCaptcha',
    method: 'post',
    data, 
  })
}

//  保存修改绑定手机号
export function changePhoneNumber(data) {
  return http.request({
    url: '/api/app/setter/changePhoneNumber',
    method: 'post',
    data, 
  })
}

//  获取验证码（重置密码）
export function sendPostRestPasswordCaptcha(data) {
  return http.request({
    url: '/api/appIndex/sendPostRestPasswordCaptcha',
    method: 'post',
    data, 
  })
}

// 重置密码
export function resetUserPassword(data) {
  return http.request({
    url: '/api/app/setter/resetUserPassword',
    method: 'post',
    data, 
  })
}
 

// 修改密码
export function updateUserPassword(data) {
  return http.request({
    url: '/api/app/setter/updateUserPassword',
    method: 'post',
    data, 
  })
}

// 注销原因
export function outTemplate(data) {
  return http.request({
    url: '/api/app/user/outTemplate',
    method: 'post',
    data, 
  })
}

// 获取验证码（注销账号）
export function sendPostUserLogoutCaptcha(data) {
  return http.request({
    url: '/api/appIndex/sendPostUserLogoutCaptcha',
    method: 'post',
    data, 
  })
}

// 注销账号
export function userLogout(data) {
  return http.request({
    url: '/api/app/user/userLogout',
    method: 'post',
    data, 
  })
}

// 开关设置配置信息
export function queryUserSetterInfo(data) {
  return http.request({
    url: '/api/app/setter/queryUserSetterInfo',
    method: 'post',
    data, 
  })
}

// 单个设置开关
export function updateUserSetterInfo(data) {
  return http.request({
    url: '/api/app/setter/updateUserSetterInfo',
    method: 'post',
    data, 
  })
}

// 黑名单列表
export function queryUserBlockList(data) {
  return http.request({
    url: '/api/app/setter/queryUserBlockList',
    method: 'post',
    data, 
  })
}

// 对指定的人隐身列表
export function queryUserHideList(data) {
  return http.request({
    url: '/api/app/setter/queryUserHideList',
    method: 'post',
    data, 
  })
}

// 根据id搜索指定的人隐身列表
export function queryUserListByIdentification(data) {
  return http.request({
    url: '/api/app/setter/queryUserListByIdentification',
    method: 'post',
    data, 
  })
}

// 版本更新信息
export function queryLatestVersion(data) {
  return http.request({
    url: '/api/appIndex/queryLatestVersion',
    method: 'post',
    data, 
  })
}

// 拉黑用户
export function addUserBlock(data) {
  return http.request({
    url: '/api/app/user/addUserBlock',
    method: 'post',
    data, 
  })
} 

// 对用户进行隐身
export function addUserHide(data) {
  return http.request({
    url: '/api/app/setter/addUserHide',
    method: 'post',
    data, 
  })
}

// 解锁微信号
export function getWechatAccount(data) {
  return http.request({
    url: '/api/app/user/unLockWxNumber',
    method: 'post',
    data,
  })
}
// 获取会员特权解锁微信号次数
export function getRemainingWeChatUnlockAttemptsForMember(data) {
  return http.request({
    url: '/api/app/user/queryVipPrivilegeNum',
    method: 'post',
    data,
  })
}
