<template>
  <van-dialog v-model:show="show_" :close-on-click-overlay="true" @close="close" class="min-h-[58.37%] border-[2px] border-[#FFDFBE]">
    <div>
      <img class="mx-auto my-[24px] block h-[24px] w-[122px]" src="@/assets/image/icon/vip-title.png" alt="" />
      <div class="min-h-[168px] px-[44px]">
        <swiper :slides-per-view="3" @slideChange="onSlideChange" @swiper="onSwiper" :space-between="0" :modules="modules" :loop="true">
          <swiper-slide v-for="(item, index) in describeList" :key="item.id" :slideIndex="index">
            <div>
              <img :src="item.picture" alt="" :class="activeImg(index)" class="h-[96px] w-[96px]" />
            </div>
          </swiper-slide>
          <div class="my-[10px] text-center text-[16px]">{{ describeName }}</div>
          <ul class="mb-[20px] flex items-center justify-center gap-[6px]">
            <li class="h-[6px] w-[6px] rounded-full" v-for="(item, index) in describeList" :key="item.id" :class="index == slideIndex ? 'bg-[#FFDFBE]' : 'bg-[#999]'"></li>
          </ul>
        </swiper>
      </div>
      <div class="no-scrollbar my-[16px] w-full overflow-x-auto pl-[16px]" ref="scrollContainer">
        <ul class="flex min-h-[124px] gap-[12px] pb-[8px]">
          <li v-for="item in priceList" :key="item.id" :class="[activeClass(item.id)]" @click="activeId = item.id" class="price-item">
            <div v-if="item.discount !== 0" class="absolute right-0 top-0 w-[42px] rounded-[6px] bg-accent-content text-center text-[13px] text-[#731803]">
              {{ item.discount }}折
            </div>
            <div class="text-accent-content">
              <a class="text-base" href="javascript:void(0);">￥</a>
              <span class="text-xl">{{ item.openMoney }}</span>
            </div>
            <div class="text-sm text-[#DBDEFF]">
              <span>{{ item.dateNum }}</span>
              <span>{{ item.dateType == 1 ? '天' : '个月' }}</span>
            </div>
          </li>
          <li class="min-w-[12px]"></li>
        </ul>
      </div>
    </div>
    <template #footer>
      <button class="btn mb-[24px] ml-[16px] w-[320px]" @click="payVipRef.open()">立即开通</button>
      <button v-if="_options.wxUnlockIntegralNum > 0" class="btn mb-[24px] ml-[16px] w-[320px]" @click="callback">
        {{ `消耗${options.wxUnlockIntegralNum}颜值币查看微信号` }}
      </button>
    </template>
  </van-dialog>
  <pay-vip ref="payVipRef" :activeId="activeId" @paySuccess="paySuccess"></pay-vip>
</template>
<script>
const defaultOptions = {
  wxUnlockIntegralNum: 0, //解锁所需积分点数
  unlockWeChatByPointsCallback: () => {}, //消耗颜值币解锁微信回调
}
</script>
<script setup>
import { ref, onMounted, defineProps, nextTick, computed, watch } from 'vue'
import { queryVipDescribeList, queryVipPriceList } from '@/api/member'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'
import 'swiper/css'

const mySwiper = ref(null)

const activeIndex = ref(0)
const slideIndex = ref(0)
const activeId = ref(1)
const describeList = ref([])
const priceList = ref([])
const payVipRef = ref(null)
const show_ = ref(false)
const scrollContainer = ref()

const modules = []

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  close: {
    type: Function,
  },
  i: {
    type: Number,
    default: 1,
  },
  options: {
    type: Object,
    default: () => defaultOptions,
  },
})
const _options = { ...defaultOptions, ...props.options }
let callback = () => {}
// 解锁微信号
if (_options.wxUnlockIntegralNum > 0) {
  callback = () => {
    _options.unlockWeChatByPointsCallback().then((res) => {
      show_.value = false
    })
  }
}
const onSwiper = (swiper) => {
  console.log(swiper)
  mySwiper.value = swiper
}

watch(
  () => props.show,
  (newVal) => {
    show_.value = newVal
  },
  { immediate: true, deep: true },
)

const activeImg = (index) => {
  if (slideIndex.value === index) {
    return ''
  } else {
    return 'scale-50'
  }
}

const close = () => {
  console.log('close')
}

const paySuccess = () => {
  console.log('paySuccess')
  show_.value = false
}

const describeName = computed(() => {
  if (!describeList.value.length) return ''
  return describeList.value[slideIndex.value].name
})

const getDescribeList = async () => {
  const res = await queryVipDescribeList()
  describeList.value = res.data
  console.log(res)
}

getDescribeList()

const getVipPriceList = async () => {
  const res = await queryVipPriceList({ systemType: 1 })
  priceList.value = res.data
  activeId.value = res.data[2].id
  if (priceList.value.length > 3) {
    setTimeout(() => {
      scrollContainer.value.scrollLeft = 100
      mySwiper.value.slideToLoop(props.i)
    }, 200)
  }
}
getVipPriceList()

const activeClass = (id) => {
  return activeId.value === id ? 'border border-accent-content' : ''
}

const onSlideChange = (swiper, index) => {
  console.log(swiper)
  console.log(mySwiper.value)
  if (!isNaN(swiper.realIndex)) {
    activeIndex.value = swiper.realIndex
    if (activeIndex.value == describeList.value.length - 1) {
      slideIndex.value = 0
    } else {
      slideIndex.value = activeIndex.value + 1
    }
  }
}

onMounted(() => {
  console.log(priceList.value.length)
})
</script>

<style scoped>
.price-item {
  @apply relative box-border flex h-[116px] min-w-[100px] flex-col items-center justify-center gap-[12px] overflow-hidden rounded bg-[#343348] shadow-lg shadow-[rgba(0,0,0,0.33)];
}

:deep(.swiper-pagination) {
  @apply mt-[30px];
}

:deep(.swiper-pagination-bullet-active) {
  @apply bg-[#FFDFBE];
}

/* 隐藏滚动条 */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
