import { defineStore } from 'pinia'
import { store } from '@/stores'
import { improveUserInfo } from '@/api/user'
import { loginByCaptcha, loginByPassword, forgetLogin,getCurrentUserVersion } from '@/api/login'
import { getUserDetail, queryMyPersonInfo } from '@/api/user/index.js';
import { getAccessToken } from '@/api/baidu/index'
import { framework } from '@/TUIKit/adapter-vue';
import router from "@/router/index"
import { logout } from '@/lib/chat'

import { TUILogin } from '@tencentcloud/tui-core';
import { TUIStore, StoreName, TUIConversationService, TUITranslateService } from '@tencentcloud/chat-uikit-engine';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    token: "",
    userinfo: {},
    totalUnreadCount: 0, // 总未读消息数
    notifyUnreadCount: 0, // 通知未读数
    chatUnreadCount: 0, // 消息未读数
    messageTabActive: 0,
    tabBarActive: 0,
    showOpenVipDialog: 0,
    verifyToken: "",
    authImage: "",
    accessToken: "",
    locationInfo: "" ,// 定位信息 
  }),
  actions: {
    async setInfo(token, userinfo) {
      this.token = token
      this.userinfo = userinfo
      this.tabBarActive = 0
      this.showOpenVipDialog = 0
       this.appKey = 1
       this.getCurrentUserVersionAction()
      // this.getAccessTokenAction()
      this.getUserDetailAction(userinfo.userId)
      this.getMyPersonInfoAction()
      try {
        await  TUILogin.logout();

        await this.chatLogin()
      } catch (error) {

      }

    },

    async chatLogin() {
      await TUILogin.login({
        SDKAppID: import.meta.env.VITE_SDK_APPID,
        userID: this.userinfo.accid,
        userSig: this.userinfo.userSign,
        useUploadPlugin: true,
        framework,
      })
    },

    // 首次登录完善信息
    async improveUserInfoAction(userinfo) {
      const res = await improveUserInfo(userinfo)
      await this.setInfo(res.data.token, res.data.user)

      router.push("/index")
    },

    // 验证码登录
    async loginByCaptchaAction(params) {
      try {
        const res = await loginByCaptcha(params)
        if (res.code !== 0) return
        await this.setInfo(res.data.token, res.data.user)
        router.push('/index')
      } catch (error) {
        console.log(error);
        if (error.code !== 301) return
        router.push({
          path: '/login/improve',
          query: {
            userId: error.data.user.userId,
          }
        })

      }
    },

    // 密码登录
    async loginByPasswordAction(params) {
      const res = await loginByPassword(params)
      await this.setInfo(res.data.token, res.data.user)

      router.push('/index')
    },


    // 忘记密码
    async forgetLoginAction(params) {
      const res = await forgetLogin(params)
      await this.setInfo(res.data.token, res.data.user)
      router.push('/index')
    },

    // 获取百度AccessToken
    async getAccessTokenAction() {
      let apiKey = import.meta.env.VITE_BAIDU_API_KEY
      let secretKey = import.meta.env.VITE_BAIDU_SECRET_KEY
      console.log(apiKey, secretKey);
      const res = await getAccessToken(apiKey, secretKey)
      console.log(res);
      if (res.access_token) {
        this.accessToken = res.access_token
      }
    },

    // 获取用户详情
    async getUserDetailAction(userId) {
      const res = await getUserDetail({ userId })
      this.userinfo = { ...this.userinfo, ...res.data }
    },

    // 获取我的主页信息
    async getMyPersonInfoAction() {
      let res = await queryMyPersonInfo()
      this.userinfo = { ...this.userinfo, ...res.data }
    },

    // 登录/注册后必传
    async getCurrentUserVersionAction() {
      await getCurrentUserVersion({
        versionName:'8.8.8',
        systemType: '1'
      }) 
    },

    // 退出登录
    logoutAction() {
      this.token = ""
      this.userinfo = {}
      this.tabBarActive = 0 
      try {
        TUILogin.logout();
      } catch (error) {
        console.log(error);
      }
      localStorage.clear()
     
      router.replace('/login/index').then(() => {
        // 确保路由替换完成后执行刷新
        location.reload();
      });
    }
  },

  persist: {
    key: "user",
    paths: ["token", "userinfo",]
  }
})

